import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useShoppingCart, useAuth } from '@ecommerce/shared'
import { MyFavoritesPage as Props } from '../types/PgPages'
import MyFavoritesWrapper from '../templates/MyFavorites'
import { sendPageViewEvent } from '../utils/events'

const MyFavoritesPage = ({ pageContext: { currentMarket, productCategories }, data }: Props) => {
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node
  const templateData = pageData?.template

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Mis favoritos' })
  }, [])

  return (
    <MyFavoritesWrapper
      description={pageData?.description}
      keywords={pageData?.keywords}
      pageTitle={pageData?.title}
      template={templateData}
      currentCity={currentMarket}
      categories={productCategories}
    />
  )
}

export default MyFavoritesPage

export const query = graphql`
  query FavoritesPage($favoritesId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $favoritesId } }) {
      edges {
        node {
          title
          description
          keywords
          template {
            ... on ContentfulTmMyFavorites {
              deleteSuccessMessage
              deleteErrorMessage
              noSkusTitle
              noSkusInfoText {
                json
              }
              noSkusIcon {
                file {
                  url
                }
              }
              noSkusButtonText
              noSkusButtonSlug
            }
          }
        }
      }
    }
  }
`
