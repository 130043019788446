import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import {
  getAuth,
  useAuth,
  useResolution,
  toCssPrefix,
  ProductCard,
  breakpoints,
  Loading,
  ProductCart,
  Market,
  showToast,
  slugify,
  getStoredDistributionCenter,
} from '@ecommerce/shared'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import { getNavBar } from '../../components/MyAccount/CustomNavBar'
import AccountLayout from '../UserMenu'
import { getProductsBySlugLocation } from '../../utils/algolia'
import { useCartStockErrorHandler } from '../../utils/errors'
import EmptySkus from './EmptySkus'
import { MyFavoritesTemplate, FlatLocationProductCategory } from '../../types/PgPages'
import { productNavigate } from '../../utils/gtm'

type Props = {
  currentCity: Market
  template: MyFavoritesTemplate
  pageTitle: string
  categories?: FlatLocationProductCategory[]
  description?: string
  keywords?: string[]
}

const { cssPrefix, toPrefix } = toCssPrefix('MyFavorites__')
const Wrapper = styled.div`
  .UserMenu__menu {
    margin-top: 79px;
  }

  .${cssPrefix} {
    &content {
      min-height: 350px;
      position: relative;
    }

    &loader {
      width: 84px;
      height: 84px;
      position: absolute;
      left: calc(50% - 42px);
      top: calc(50% - 42px);
    }

    &title {
      width: 335px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
      padding-bottom: 30px;
      margin: 0 auto 25px;
    }

    &top-bar {
      button {
        position: absolute;
        right: 0;
        top: 24px;
      }
    }

    &grid {
      display: grid;
      gap: 24px;
      place-content: center;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &title {
        width: 100%;
      }

      &grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }
  }
`

const MyFavoritesWrapper = ({ currentCity, template, pageTitle, categories, description, keywords }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState<ProductCart[]>()
  const { isDesktop } = useResolution()

  const {
    state: { firstName, lastName, favoriteSkus },
  } = useAuth()
  const isAuth = getAuth()
  const currentDistributionCenter = getStoredDistributionCenter()

  const NavBar = isDesktop
    ? undefined
    : () => getNavBar({ slug: '../', onLogoClick: () => navigate(`/`), currentMarket: currentCity, categories })

  const stockErrorHandler = useCartStockErrorHandler()

  const showError = () => {
    return showToast({ title: 'Upps!', content: template.deleteErrorMessage, type: 'error' })
  }

  const renderContent = () => {
    if (isLoading) return <Loading className={toPrefix('loader')} />
    if ((!products || products.length === 0) && !isLoading) {
      return (
        <EmptySkus
          icon={template.noSkusIcon.file.url}
          infoText={template.noSkusInfoText}
          title={template.noSkusTitle}
          buttonText={template.noSkusButtonText}
          buttonSlug={template.noSkusButtonSlug ? `/${template.noSkusButtonSlug}` : undefined}
        />
      )
    }

    return (
      <div className={toPrefix('grid')}>
        {products?.map((product) => (
          <ProductCard
            key={product.skuCode}
            onNavigate={() => {
              if (!product.unavailable) {
                return productNavigate({ product, listName: 'Favoritos Mi Cuenta' })
              }
            }}
            stockErrorHandler={stockErrorHandler}
            product={product}
          />
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (!isAuth) {
      navigate(`/`)

      return
    }
    if (currentDistributionCenter) {
      const fetchData = async () => {
        try {
          setIsLoading(true)

          const skus = await getProductsBySlugLocation(
            slugify(currentDistributionCenter?.commerceLayer.stockLocation.name),
            favoriteSkus?.map(String),
          )
          setProducts(skus ?? [])
        } catch (error) {
          showError()
          setProducts([])
        } finally {
          setIsLoading(false)
        }
      }

      if (!favoriteSkus || favoriteSkus.length === 0) {
        setIsLoading(false)
        return setProducts([])
      }

      fetchData()
    }
  }, [favoriteSkus])

  return (
    <Layout description={description} keywords={keywords} navbar={NavBar} title={pageTitle} categories={categories}>
      <Wrapper className={cssPrefix}>
        <AccountLayout
          className={cssPrefix}
          customerName={`${firstName} ${lastName}`}
          title="Mis Favoritos"
          titleClassName={toPrefix('title')}
          contentClassName={toPrefix('content')}
          topBarClassName={toPrefix('top-bar')}
          activeMenuIndex={4}
        >
          {renderContent()}
        </AccountLayout>
      </Wrapper>
    </Layout>
  )
}

export default MyFavoritesWrapper
