import React from 'react'
import { Button, toCssPrefix, Image } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { RichTextJson } from '../../graphql/contentfulTypes'

type Props = {
  title: string
  infoText: RichTextJson
  icon: string
  buttonText: string
  buttonSlug?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('EmptySkus__')
const Wrapper = styled.div`
  max-width: 300px;
  margin: auto;
  text-align: center;

  .${cssPrefix} {
    &heading {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
    }

    &info {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: block;
      margin-bottom: 25px;
    }

    &action-button {
      width: 100%;
    }

    &icon {
      width: 120px;
      height: 120px;
      object-fit: contain;
      margin: 45px auto;
      display: block;
    }
  }
`

const EmptySkus = ({ title, icon, infoText, buttonText, buttonSlug }: Props) => {
  return (
    <Wrapper className={cssPrefix}>
      {title && <p className={toPrefix('heading')}>Aún no tienes productos agregados en Mis Favoritos</p>}
      {icon && <Image className={toPrefix('icon')} alt="escoge-tus-productos" src={icon} />}
      {infoText && <span className={toPrefix('info')}>{documentToReactComponents(infoText.json)}</span>}
      {buttonText && (
        <Button
          isDisabled={!buttonSlug}
          onClick={() => (buttonSlug ? navigate(buttonSlug) : null)}
          className={toPrefix('action-button')}
        >
          Ver catálogo
        </Button>
      )}
    </Wrapper>
  )
}

export default EmptySkus
